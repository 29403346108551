import React, { useState, useEffect } from 'react'
import { Typography, Skeleton } from '@mui/material'

import { firebaseUtils } from 'utils'
import Image from '../Image'

type RemoteImageProps = Omit<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >,
  'src'
> & {
  filePath?: string | null
}

const DEFAULT_SIZE = 200

const RemoteImage: React.FC<RemoteImageProps> = ({
  filePath,
  ...props
}: RemoteImageProps) => {
  const [downloadUrl, setDownloadUrl] = useState<string>()
  const width = props?.width || DEFAULT_SIZE
  const height = props?.height || DEFAULT_SIZE

  useEffect(() => {
    if (!filePath) {
      return
    }

    const getDownloadUrl = async () => {
      const urlFile = await firebaseUtils.getUrlFile(filePath)
      setDownloadUrl(urlFile)
    }

    getDownloadUrl()
  }, [filePath])

  return !filePath ? (
    <Typography variant='body2'>No image</Typography>
  ) : downloadUrl ? (
    <Image
      src={downloadUrl}
      width={width}
      height={height}
      alt='image'
      {...props}
    />
  ) : (
    <Skeleton variant='rectangular' width={width} height={height} />
  )
}

export default RemoteImage
