import React from 'react'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers-pro'
import { DefaultTFuncReturn } from 'i18next'

type MuiDatePickerProps = DatePickerProps<Date> & {
  helperText?: string | DefaultTFuncReturn
  error?: boolean
  fullWidth?: boolean
}

const MuiDatePicker: React.FC<MuiDatePickerProps> = ({
  helperText,
  error = false,
  fullWidth = true,
  ...props
}: MuiDatePickerProps) => {
  return (
    <DatePicker
      slotProps={{
        textField: {
          helperText,
          error,
          fullWidth,
          margin: 'normal'
        }
      }}
      {...props}
    />
  )
}

export default MuiDatePicker
