const truncateId = (id: string, length = 8): string => {
  if (!id) {
    return ''
  }

  return `${id?.slice(0, length)}...${id?.slice(id?.length - length)}`
}

export const numberToString = (value?: number | null): string =>
  typeof value === 'number' ? value?.toString() : '0'

export const toString = (value?: string | null): string => value ?? ''

export const boolToString = (value?: boolean | null): string =>
  Boolean(value || false)?.toString()

export default {
  truncateId,
  toString,
  boolToString
}
