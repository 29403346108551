import { initializeApp, FirebaseApp } from 'firebase/app'
import {
  getAuth,
  //  connectAuthEmulator,
  Auth
} from 'firebase/auth'
import {
  getStorage,
  connectStorageEmulator,
  FirebaseStorage
} from 'firebase/storage'
import {
  getFunctions,
  connectFunctionsEmulator,
  Functions
} from 'firebase/functions'
import { getAnalytics, Analytics } from 'firebase/analytics'

import { firebaseConfig } from 'config'

let firebaseApp: FirebaseApp | undefined
let auth: Auth | undefined
let functions: Functions | undefined
let storage: FirebaseStorage | undefined
let analytics: Analytics | undefined

if (!firebaseApp || !auth || !functions) {
  const {
    appName,
    useEmulator,
    // authEmulatorPort,
    functionsEmulatorPort,
    storageBucket,
    storageEmulatorPort,
    apiKey,
    authDomain,
    projectId,
    messagingSenderId,
    appId,
    measurementId
  } = firebaseConfig

  firebaseApp = initializeApp(
    {
      apiKey,
      authDomain,
      projectId,
      storageBucket,
      messagingSenderId,
      appId,
      measurementId
    },
    appName
  )
  auth = getAuth(firebaseApp)
  functions = getFunctions(firebaseApp)
  storage = getStorage(firebaseApp, storageBucket)
  analytics = getAnalytics(firebaseApp)

  if (useEmulator) {
    // connectAuthEmulator(auth, `http://localhost:${authEmulatorPort}`, {
    //   disableWarnings: true
    // })
    connectFunctionsEmulator(
      functions,
      'localhost',
      Number(functionsEmulatorPort)
    )
    connectStorageEmulator(storage, 'localhost', Number(storageEmulatorPort))
  }
}

const firebaseLib = {
  firebaseApp: firebaseApp as FirebaseApp,
  auth: auth as Auth,
  functions: functions as Functions,
  storage: storage as FirebaseStorage,
  analytics: analytics as Analytics
}

export default firebaseLib
