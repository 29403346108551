import React from 'react'

import CommentFormDialog from '../CommentFormDialog'
import {
  SupportTicketFragmentFragment,
  useInsertSupportTicketCommentMutation
} from 'api/generated'
import { useUser } from 'context/auth/user'

export type SupportTicketCommentFormDialogProps = {
  supportTicket?: SupportTicketFragmentFragment
  openDialog: boolean
  onCancel: () => void
}

const SupportTicketCommentFormDialog: React.FC<
  SupportTicketCommentFormDialogProps
> = ({
  supportTicket,
  openDialog,
  onCancel
}: SupportTicketCommentFormDialogProps) => {
  const { dbUser } = useUser()
  const [insertSupportTicketComment, { loading, error }] =
    useInsertSupportTicketCommentMutation()

  return (
    <CommentFormDialog
      loading={loading}
      error={error}
      openDialog={openDialog}
      onCancel={onCancel}
      onSubmit={(data, onCompletedCallback) => {
        insertSupportTicketComment({
          variables: {
            object: {
              support_ticket_id: supportTicket?.id,
              user_id: dbUser?.id,
              comment: data?.comment,
              files: {
                data: data?.files?.map(file => {
                  return {
                    path: file?.value,
                    folder: 'support'
                  }
                })
              }
            }
          },
          onCompleted: data => {
            if (data?.supportTicketComment) {
              onCompletedCallback?.()
            }
          }
        })
      }}
    />
  )
}

export default SupportTicketCommentFormDialog
