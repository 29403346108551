import React, { Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Divider,
  Skeleton,
  Typography,
  Box,
  ListItemButton,
  ListItem,
  List
} from '@mui/material'
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material'
import { formatDistanceToNow } from 'date-fns'
import { ApolloError } from '@apollo/client'

import { QueryError, MuiLink } from 'components'
import { BaseBuildingTourFragmentFragment } from 'api/generated'
import { useThemeType } from 'context/theme'

export type BuildingToursListProps = {
  buildingTours?: BaseBuildingTourFragmentFragment[]
  loading?: boolean
  error?: ApolloError
  refetch?(): void
}

const BuildingToursList: React.FC<BuildingToursListProps> = ({
  buildingTours,
  loading,
  error,
  refetch
}: BuildingToursListProps) => {
  const { t } = useTranslation()
  const { dateFnsLocale } = useThemeType()

  const handleRefetchQuery = useCallback(() => {
    refetch?.()
  }, [refetch])

  return (
    <>
      {error ? (
        <QueryError
          error={error}
          loading={loading}
          refetch={handleRefetchQuery}
        />
      ) : loading ? (
        <Skeleton variant='rectangular' width='100%' height='40vh' />
      ) : buildingTours?.length ? (
        <List sx={{ height: '40vh', overflow: 'auto', width: '100%' }}>
          {buildingTours?.map(buildingTour => {
            return (
              <Fragment key={buildingTour?.id}>
                <ListItem secondaryAction={<ChevronRightIcon />} disablePadding>
                  <ListItemButton
                    component={MuiLink}
                    to={`/building-tours/${buildingTour?.id}`}
                  >
                    <Box flex={1} display='flex' flexDirection='column'>
                      <Typography variant='subtitle2'>
                        {buildingTour?.building?.name}
                      </Typography>

                      <Typography variant='caption'>
                        {`${buildingTour?.date} / ${buildingTour?.value}`}
                      </Typography>
                    </Box>

                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='flex-end'
                    >
                      <Typography variant='subtitle2'>
                        {t(buildingTour?.status)}
                      </Typography>

                      <Typography variant='caption'>
                        {formatDistanceToNow(
                          new Date(buildingTour?.created_at),
                          {
                            addSuffix: true,
                            locale: dateFnsLocale
                          }
                        )}
                      </Typography>
                    </Box>
                  </ListItemButton>
                </ListItem>
                <Divider component='li' />
              </Fragment>
            )
          })}
        </List>
      ) : (
        <Box
          height='40vh'
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Typography>{t('noRowsLabel')}</Typography>
        </Box>
      )}
    </>
  )
}

export default BuildingToursList
