import React from 'react'
import { Stack, Box, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SupportTicketFragmentFragment } from 'api/generated'
import RemoteImage from '../RemoteImage'

type SupportTicketDetailPanelProps = {
  data: SupportTicketFragmentFragment
}

const SupportTicketDetailPanel: React.FC<SupportTicketDetailPanelProps> = ({
  data
}: SupportTicketDetailPanelProps) => {
  const { t } = useTranslation()

  return (
    <Box p={2} height='100%'>
      <Paper sx={{ height: '100%' }}>
        <Stack p={2}>
          <Typography variant='h6'>{t('images')}</Typography>
          <Stack direction='row' spacing={2}>
            {data?.photos?.map(photo => {
              return (
                <Stack key={photo?.id} spacing={2}>
                  <RemoteImage filePath={photo?.path} />
                </Stack>
              )
            })}
          </Stack>
        </Stack>
      </Paper>
    </Box>
  )
}

export default SupportTicketDetailPanel
