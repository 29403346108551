import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Stack,
  CardHeader,
  CardContent,
  Divider,
  Skeleton,
  Tooltip,
  Box,
  useMediaQuery,
  useTheme,
  IconButton
} from '@mui/material'
import { PieChart } from '@mui/x-charts-pro'
import groupBy from 'lodash.groupby'
import { TransferWithinAStation as TransferWithinAStationIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import { MuiCard, QueryError, BuildingToursList } from 'components'
import { Order_By, useGetBaseBuildingToursQuery } from 'api/generated'
import { getBuildingTourStatusColorPalette } from 'utils/buildingTour'
import { PieCenterLabel } from 'components/MuiPieChart'

const UserApplicationsChart: React.FC = () => {
  const { t } = useTranslation()
  const { breakpoints, palette } = useTheme()
  const navigate = useNavigate()
  const { data, loading, error, refetch } = useGetBaseBuildingToursQuery({
    variables: {
      where: { deleted_at: { _is_null: true } },
      order_by: { created_at: Order_By.Desc }
    }
  })

  const aggregate = data?.buildingToursAggregate?.aggregate
  const totalCount = aggregate?.totalCount
  const smDown = useMediaQuery(breakpoints.down('sm'))

  const seriesData = useMemo(() => {
    const buildingTours = data?.buildingTours

    if (!buildingTours?.length) {
      return []
    }

    const buildingToursGroupedByStatus = groupBy(buildingTours, 'status')
    return Object.keys(buildingToursGroupedByStatus)?.map(status => {
      return {
        id: status,
        value: buildingToursGroupedByStatus?.[status]?.length || 0,
        label: `${t(status)}`,
        color: getBuildingTourStatusColorPalette(status, palette)
      }
    })
  }, [data, t, palette])

  const handleRefetchQuery = useCallback(() => {
    refetch()
  }, [refetch])

  const handleShowBuildingToursInDetailTable = useCallback(() => {
    navigate('building-tours')
  }, [navigate])

  return (
    <MuiCard>
      <CardHeader
        title={t('tours')}
        action={
          <Tooltip title={t('showInDetail')}>
            <IconButton onClick={handleShowBuildingToursInDetailTable}>
              <TransferWithinAStationIcon />
            </IconButton>
          </Tooltip>
        }
      />

      <CardContent>
        {error ? (
          <QueryError
            error={error}
            loading={loading}
            refetch={handleRefetchQuery}
          />
        ) : loading && !data ? (
          <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
            divider={
              <Divider
                orientation={smDown ? 'horizontal' : 'vertical'}
                flexItem
              />
            }
          >
            <Skeleton
              variant='rectangular'
              width='100%'
              sx={{ minWidth: 320 }}
              height={364}
            />
            <Skeleton
              variant='rectangular'
              width='100%'
              sx={{ minWidth: 320 }}
              height={364}
            />
          </Stack>
        ) : (
          <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
            divider={
              <Divider
                orientation={smDown ? 'horizontal' : 'vertical'}
                flexItem
              />
            }
          >
            <Box flex={1}>
              <PieChart
                series={[
                  {
                    data: seriesData,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    innerRadius: 75,
                    outerRadius: 100,
                    paddingAngle: 0
                  }
                ]}
                height={300}
                margin={{ top: 10, bottom: 100, left: 10, right: 10 }}
                slotProps={{
                  legend: {
                    direction: 'row',
                    position: { vertical: 'bottom', horizontal: 'middle' },
                    padding: 0
                  }
                }}
              >
                {' '}
                <PieCenterLabel
                  primaryText={totalCount || 0}
                  secondaryText={t('total')}
                />
              </PieChart>
            </Box>

            <Box flex={1}>
              <BuildingToursList buildingTours={data?.buildingTours} />
            </Box>
          </Stack>
        )}
      </CardContent>
    </MuiCard>
  )
}

export default UserApplicationsChart
