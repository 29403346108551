import React from 'react'

import CommentListForm from '../CommentListForm'
import {
  GetApartmentChecklistCommentsDocument,
  GetApartmentChecklistCommentsQuery,
  Order_By,
  useGetApartmentChecklistCommentsQuery,
  useInsertApartmentChecklistCommentMutation
} from 'api/generated'
import { useUser } from 'context/auth/user'

export type ApartmentChecklistCommentListFormProps = {
  id?: string
}

const ApartmentChecklistCommentListForm: React.FC<
  ApartmentChecklistCommentListFormProps
> = ({ id }: ApartmentChecklistCommentListFormProps) => {
  const { dbUser } = useUser()
  const queryVariables = {
    where: {
      apartment_checklist_id: {
        _eq: id
      }
    },
    order_by: { created_at: Order_By.Desc }
  }
  const { data, loading, error, refetch } =
    useGetApartmentChecklistCommentsQuery({
      variables: queryVariables
    })
  const [
    insertApartmentChecklistComment,
    { loading: mutationLoading, error: mutationError }
  ] = useInsertApartmentChecklistCommentMutation({
    update: (cache, { data }) => {
      const apartmentChecklistComment = data?.apartmentChecklistComment

      if (apartmentChecklistComment) {
        const existingData = cache.readQuery({
          query: GetApartmentChecklistCommentsDocument,
          variables: queryVariables
        }) as GetApartmentChecklistCommentsQuery

        const apartmentChecklistCommentsAggregate =
          existingData?.apartmentChecklistCommentsAggregate
        const apartmentChecklistComments =
          existingData?.apartmentChecklistComments

        cache.writeQuery({
          query: GetApartmentChecklistCommentsDocument,
          variables: queryVariables,
          data: {
            apartmentChecklistCommentsAggregate,
            apartmentChecklistComments: [
              apartmentChecklistComment,
              ...apartmentChecklistComments
            ]
          }
        })
      }
    }
  })

  return (
    <CommentListForm
      data={data?.apartmentChecklistComments?.map(comment => {
        return {
          id: comment?.id,
          user: comment?.user,
          comment: comment?.comment,
          createdAt: comment?.created_at as string,
          files: comment?.files
        }
      })}
      loading={loading && !data}
      error={error}
      refetch={refetch}
      onSubmit={(data, onCompletedCallback) => {
        insertApartmentChecklistComment({
          variables: {
            object: {
              apartment_checklist_id: id,
              user_id: dbUser?.id,
              comment: data?.comment,
              files: {
                data: data?.files?.map(file => {
                  return {
                    path: file?.value,
                    folder: 'apartment-checklist'
                  }
                })
              }
            }
          },
          onCompleted: data => {
            if (data?.apartmentChecklistComment) {
              onCompletedCallback?.()
            }
          }
        })
      }}
      mutationLoading={mutationLoading}
      mutationError={mutationError}
    />
  )
}

export default ApartmentChecklistCommentListForm
