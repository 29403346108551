import React, { HTMLProps } from 'react'

import logo from 'assets/logo.png'

type LogoProps = HTMLProps<HTMLImageElement>

const Logo: React.FC<LogoProps> = props => {
  return <img src={logo} alt='Livdomo icon' {...props} />
}

export default Logo
