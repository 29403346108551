import React, { useCallback } from 'react'
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Toolbar,
  IconButton,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  CSSObject,
  styled
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
  AccountCircle as AccountCircleIcon,
  SupervisorAccount as SupervisorAccountIcon,
  Apartment as ApartmentIcon,
  DoorFront as DoorFrontIcon,
  Business as BusinessIcon,
  DevicesOther as DevicesOtherIcon,
  Logout as LogoutIcon,
  AssignmentInd as AssignmentIndIcon,
  SupportAgent as SupportAgentIcon,
  TransferWithinAStation as TransferWithinAStationIcon,
  Checklist as ChecklistIcon,
  Reviews as ReviewsIcon,
  Send as SendIcon
} from '@mui/icons-material'
import { useLocation } from 'react-router-dom'

import MuiLink from 'components/MuiLink'
import { useAuth } from 'context/auth/auth'
import Logo from 'components/Logo'
import { useThemeType } from 'context/theme'

export const DRAWER_WIDTH = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

export type HeaderProps = {
  drawerOpen?: boolean
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const menuItems = [
  {
    path: '/users',
    name: 'users',
    icon: (color?: 'primary' | 'inherit') => (
      <SupervisorAccountIcon color={color} />
    )
  },
  {
    path: '/buildings',
    name: 'buildings',
    icon: (color?: 'primary' | 'inherit') => <ApartmentIcon color={color} />
  },
  {
    path: '/apartments',
    name: 'apartments',
    icon: (color?: 'primary' | 'inherit') => <DoorFrontIcon color={color} />
  },
  {
    path: '/apartment-types',
    name: 'apartmentTypes',
    icon: (color?: 'primary' | 'inherit') => <BusinessIcon color={color} />
  },
  {
    path: '/devices',
    name: 'devices',
    icon: (color?: 'primary' | 'inherit') => <DevicesOtherIcon color={color} />
  },
  {
    path: '/user-applications',
    name: 'userApplications',
    icon: (color?: 'primary' | 'inherit') => <AssignmentIndIcon color={color} />
  },
  {
    path: '/support-tickets',
    name: 'supportTickets',
    icon: (color?: 'primary' | 'inherit') => <SupportAgentIcon color={color} />
  },
  {
    path: '/building-tours',
    name: 'tours',
    icon: (color?: 'primary' | 'inherit') => (
      <TransferWithinAStationIcon color={color} />
    )
  },
  {
    path: '/apartment-checklists',
    name: 'checklist',
    icon: (color?: 'primary' | 'inherit') => <ChecklistIcon color={color} />
  },
  {
    path: '/user-living-reviews',
    name: 'livingReviews',
    icon: (color?: 'primary' | 'inherit') => <ReviewsIcon color={color} />
  },
  {
    path: '/user-notifications',
    name: 'userNotifications',
    icon: (color?: 'primary' | 'inherit') => <SendIcon color={color} />
  },
  {
    path: '/profile',
    name: 'profile',
    icon: (color?: 'primary' | 'inherit') => <AccountCircleIcon color={color} />
  }
]

const Header: React.FC<HeaderProps> = ({
  drawerOpen,
  setDrawerOpen
}: HeaderProps) => {
  const { t } = useTranslation()
  const {
    mediaQueries: { mdDown }
  } = useThemeType()
  const { logout } = useAuth()
  const location = useLocation()
  const pathname = location?.pathname

  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen(currentDrawerOpen => !currentDrawerOpen)
  }, [setDrawerOpen])

  const handleCloseDrawer = useCallback(() => {
    setDrawerOpen(false)
  }, [setDrawerOpen])

  const drawer = (
    <>
      <DrawerHeader />

      <List>
        {menuItems?.map(item => {
          const isSelected = pathname?.includes(item?.path)

          return (
            <MuiLink key={item?.path} to={item?.path} color='inherit'>
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
                onClick={mdDown ? handleCloseDrawer : undefined}
              >
                <ListItemButton
                  selected={isSelected}
                  sx={{
                    minHeight: 48,
                    justifyContent: drawerOpen ? 'initial' : 'center',
                    px: 2.5
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: drawerOpen ? 3 : 'auto',
                      justifyContent: 'center'
                    }}
                  >
                    {item?.icon(isSelected ? 'primary' : 'inherit')}
                  </ListItemIcon>
                  <ListItemText
                    primary={t(item?.name)}
                    primaryTypographyProps={{
                      color: isSelected ? 'primary' : 'text'
                    }}
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </MuiLink>
          )
        })}

        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            onClick={logout}
            sx={{
              minHeight: 48,
              justifyContent: drawerOpen ? 'initial' : 'center',
              px: 2.5
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: drawerOpen ? 3 : 'auto',
                justifyContent: 'center'
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('logOut')}
              sx={{ opacity: drawerOpen ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  )

  return (
    <>
      <AppBar position='fixed' color='default'>
        <Toolbar>
          <IconButton
            color='inherit'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{
              marginRight: {
                lg: 4,
                xl: 4
              }
            }}
          >
            {drawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
          <MuiLink to={`/`}>
            <Logo height={50} />
          </MuiLink>
        </Toolbar>
      </AppBar>

      <Drawer
        variant='permanent'
        open={drawerOpen}
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' }
        }}
      >
        {drawer}
      </Drawer>

      <MuiDrawer
        variant='temporary'
        open={drawerOpen}
        onClose={handleCloseDrawer}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH }
        }}
      >
        {drawer}
      </MuiDrawer>
    </>
  )
}

export default Header
