import { GridValueFormatter } from '@mui/x-data-grid-pro'

export type FormatCurrencyProps = {
  amount?: number | null
  locales?: string | string[]
  options?: Intl.NumberFormatOptions
  currency?: string
}

export const formatCurrency = ({
  amount = 0,
  locales = 'en-US',
  currency = 'USD',
  options = {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol'
  }
}: FormatCurrencyProps): string => {
  return new Intl.NumberFormat(locales, {
    ...options,
    ...(currency ? { currency } : {})
  }).format(amount || 0)
}

export const dataGridCurrencyFormatter: GridValueFormatter = (
  value: number
) => {
  return formatCurrency({
    amount: value
  })
}

export default {
  formatCurrency,
  dataGridCurrencyFormatter
}
