import { getDownloadURL, ref } from 'firebase/storage'

import firebaseLib from 'lib/firebase'
import { firebaseConfig } from 'config'

const { storage } = firebaseLib

export const getUrlFile = (filePath: string): Promise<string> | undefined =>
  filePath ? getDownloadURL(ref(storage, filePath)) : undefined

export const getUserApplicationPDFUrl = (id: string): string =>
  `${firebaseConfig.functionsBaseUrl}/userApplicationPDF?id=${id}`

export default {
  getUrlFile,
  getUserApplicationPDFUrl
}
