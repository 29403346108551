import React from 'react'

import CommentFormDialog from '../CommentFormDialog'
import {
  UserApplicationFragmentFragment,
  useInsertUserApplicationCommentMutation
} from 'api/generated'
import { useUser } from 'context/auth/user'

export type UserApplicationCommentFormDialogProps = {
  userApplication?: UserApplicationFragmentFragment
  openDialog: boolean
  onCancel: () => void
}

const UserApplicationCommentFormDialog: React.FC<
  UserApplicationCommentFormDialogProps
> = ({
  userApplication,
  openDialog,
  onCancel
}: UserApplicationCommentFormDialogProps) => {
  const { dbUser } = useUser()
  const [insertUserApplicationComment, { loading, error }] =
    useInsertUserApplicationCommentMutation()

  return (
    <CommentFormDialog
      loading={loading}
      error={error}
      openDialog={openDialog}
      onCancel={onCancel}
      onSubmit={(data, onCompletedCallback) => {
        insertUserApplicationComment({
          variables: {
            object: {
              user_application_id: userApplication?.id,
              user_id: dbUser?.id,
              comment: data?.comment,
              files: {
                data: data?.files?.map(file => {
                  return {
                    path: file?.value,
                    folder: 'user-application'
                  }
                })
              }
            }
          },
          onCompleted: data => {
            if (data?.userApplicationComment) {
              onCompletedCallback?.()
            }
          }
        })
      }}
    />
  )
}

export default UserApplicationCommentFormDialog
