import { linkConfig } from 'config'

const baseUrl = linkConfig?.baseUrl

export const LINKS = [
  {
    label: 'intercomHome',
    value: `${baseUrl}/intercom/HOME`
  },
  {
    label: 'intercomMessages',
    value: `${baseUrl}/intercom/MESSAGES`
  },
  {
    label: 'intercomHelpCenter',
    value: `${baseUrl}/intercom/HELP_CENTER`
  },
  {
    label: 'intercomTickets',
    value: `${baseUrl}/intercom/TICKETS`
  },
  {
    label: 'communityScreen',
    value: `${baseUrl}/community`
  },
  {
    label: 'devicesScreen',
    value: `${baseUrl}/devices`
  },
  {
    label: 'settingsScreen',
    value: `${baseUrl}/settings`
  },
  {
    label: 'profileScreen',
    value: `${baseUrl}/profile`
  },
  {
    label: 'scenesScreen',
    value: `${baseUrl}/scenes`
  }
]

export default {
  LINKS
}
