import React from 'react'

import CommentListForm from '../CommentListForm'
import {
  GetSupportTicketCommentsDocument,
  GetSupportTicketCommentsQuery,
  Order_By,
  useGetSupportTicketCommentsQuery,
  useInsertSupportTicketCommentMutation
} from 'api/generated'
import { useUser } from 'context/auth/user'

export type SupportTicketCommentListFormProps = {
  id?: string
}

const SupportTicketCommentListForm: React.FC<
  SupportTicketCommentListFormProps
> = ({ id }: SupportTicketCommentListFormProps) => {
  const { dbUser } = useUser()
  const queryVariables = {
    where: {
      support_ticket_id: {
        _eq: id
      }
    },
    order_by: { created_at: Order_By.Desc }
  }
  const { data, loading, error, refetch } = useGetSupportTicketCommentsQuery({
    variables: queryVariables
  })
  const [
    insertSupportTicketComment,
    { loading: mutationLoading, error: mutationError }
  ] = useInsertSupportTicketCommentMutation({
    update: (cache, { data }) => {
      const supportTicketComment = data?.supportTicketComment

      if (supportTicketComment) {
        const existingData = cache.readQuery({
          query: GetSupportTicketCommentsDocument,
          variables: queryVariables
        }) as GetSupportTicketCommentsQuery

        const supportTicketCommentsAggregate =
          existingData?.supportTicketCommentsAggregate
        const supportTicketComments = existingData?.supportTicketComments

        cache.writeQuery({
          query: GetSupportTicketCommentsDocument,
          variables: queryVariables,
          data: {
            supportTicketCommentsAggregate,
            supportTicketComments: [
              supportTicketComment,
              ...supportTicketComments
            ]
          }
        })
      }
    }
  })

  return (
    <CommentListForm
      data={data?.supportTicketComments?.map(comment => {
        return {
          id: comment?.id,
          user: comment?.user,
          comment: comment?.comment,
          createdAt: comment?.created_at as string,
          files: comment?.files
        }
      })}
      loading={loading && !data}
      error={error}
      refetch={refetch}
      onSubmit={(data, onCompletedCallback) => {
        insertSupportTicketComment({
          variables: {
            object: {
              support_ticket_id: id,
              user_id: dbUser?.id,
              comment: data?.comment,
              files: {
                data: data?.files?.map(file => {
                  return {
                    path: file?.value,
                    folder: 'support'
                  }
                })
              }
            }
          },
          onCompleted: data => {
            if (data?.supportTicketComment) {
              onCompletedCallback?.()
            }
          }
        })
      }}
      mutationLoading={mutationLoading}
      mutationError={mutationError}
    />
  )
}

export default SupportTicketCommentListForm
