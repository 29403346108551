import { fileArraySchema, FileType, object, stringRequired } from '../common'

export type CommentSchemaType = {
  comment: string
  files: FileType[]
}

export const commentSchema = object.shape({
  comment: stringRequired,
  files: fileArraySchema.required()
})

export const defaultCommentValues: CommentSchemaType = {
  comment: '',
  files: []
}
