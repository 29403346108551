import {
  object,
  stringRequired,
  string,
  uuidRequired,
  dateRequired
} from '../common'

export type SendUserNotificationSchemaType = {
  senderMethod: string
  message: string
  subject: string | undefined
  link: string | undefined
}

export const sendUserNotificationSchema = object.shape({
  senderMethod: stringRequired,
  message: stringRequired,
  subject: string,
  link: string
})

export const defaultSendUserNotificationValues: SendUserNotificationSchemaType =
  {
    senderMethod: '',
    message: '',
    subject: '',
    link: ''
  }

export type UserNotificationSchemaType = {
  id: string
  message: string
  subject: string
  senderType: string
  senderResponse: object
  from: string
  to: string
  tableName: string
  rowId: string
  user: string
  senderUser: string
  createdAt: Date
}

export const userNotificationSchema = object.shape({
  id: uuidRequired,
  message: stringRequired,
  subject: stringRequired,
  senderType: stringRequired,
  senderResponse: object,
  from: stringRequired,
  to: stringRequired,
  tableName: stringRequired,
  rowId: stringRequired,
  user: stringRequired,
  senderUser: stringRequired,
  createdAt: dateRequired
})

export const defaultUserNotificationValues: UserNotificationSchemaType = {
  id: '',
  message: '',
  subject: '',
  senderType: '',
  senderResponse: {},
  from: '',
  to: '',
  tableName: '',
  rowId: '',
  user: '',
  senderUser: '',
  createdAt: new Date()
}
