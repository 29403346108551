import React from 'react'
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers-pro'
import { DefaultTFuncReturn } from 'i18next'

type MuiTimePickerProps = TimePickerProps<Date> & {
  helperText?: string | DefaultTFuncReturn
  error?: boolean
  fullWidth?: boolean
}

const MuiTimePicker: React.FC<MuiTimePickerProps> = ({
  helperText,
  error = false,
  fullWidth = true,
  ...props
}: MuiTimePickerProps) => {
  return (
    <TimePicker
      slotProps={{
        textField: {
          helperText,
          error,
          fullWidth,
          margin: 'normal'
        }
      }}
      {...props}
    />
  )
}

export default MuiTimePicker
