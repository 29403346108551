import React, { createContext, useContext } from 'react'

import { useAuth } from './auth'
import { ProviderProps, UserContextProps } from './types'
import { User_Role_Enum } from 'api/generated'
import { authUtils } from 'utils'

const UserContext = createContext<UserContextProps | undefined>(undefined)

const UserProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const { firebaseUser, dbUser } = useAuth()
  const userRole = dbUser?.role
  const isAdminUser = userRole !== User_Role_Enum.Anonymous
  const allowedRoles = authUtils.getAllowedRolesByUserRole(userRole)

  return (
    <UserContext.Provider
      value={{
        firebaseUser,
        dbUser,
        isAdminUser,
        allowedRoles
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

const useUser = (): UserContextProps => {
  const context = useContext(UserContext)

  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`)
  }

  return context
}

export { UserProvider, useUser }
