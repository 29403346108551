import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Box,
  Tooltip,
  IconButton
} from '@mui/material'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Send as SendIcon,
  Upload as UploadIcon,
  Close as CloseIcon
} from '@mui/icons-material'
import { uploadBytes, ref as firebaseRef } from 'firebase/storage'
import { ApolloError } from '@apollo/client'

import MuiButton from '../MuiButton'
import MuiTextField from '../MuiTextField'
import MuiSnackbar from '../MuiSnackbar'
import {
  CommentSchemaType,
  commentSchema,
  defaultCommentValues
} from 'schemas/comment'
import { defaultFileValues } from 'schemas/common'
import FileUploadPreview from '../FileUploadPreview'
import firebase from 'lib/firebase'

const { storage } = firebase

export type CommentFormDialogProps = {
  onCancel: () => void
  onSubmit: (data: CommentSchemaType, onCompletedCallback?: () => void) => void
  openDialog: boolean
  loading: boolean
  error?: ApolloError
}

const CommentFormDialog: React.FC<CommentFormDialogProps> = ({
  onCancel,
  onSubmit,
  openDialog,
  loading,
  error
}) => {
  const { t } = useTranslation()
  const { control, handleSubmit, reset } = useForm<CommentSchemaType>({
    resolver: yupResolver(commentSchema),
    defaultValues: defaultCommentValues
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'files'
  })

  const handleOnClose = useCallback(() => {
    onCancel()
    reset()
  }, [onCancel, reset])

  const handleOnSubmit = handleSubmit(data => {
    onSubmit(data, () => {
      handleOnClose()
    })
  })

  const handleAppendFile = useCallback(() => {
    append(defaultFileValues)
  }, [append])

  return (
    <>
      <MuiSnackbar
        snackbarProps={{
          open: !!error
        }}
        alertProps={{
          severity: 'error'
        }}
        message={error?.message}
      />

      <Dialog
        open={openDialog}
        PaperProps={{
          component: 'form',
          onSubmit: handleOnSubmit
        }}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle>{t('comment')}</DialogTitle>
        <DialogContent>
          <Controller
            name='comment'
            control={control}
            defaultValue=''
            render={({
              field: { ref, ...fieldProps },
              fieldState: { error }
            }) => {
              return (
                <MuiTextField
                  label={t('comment')}
                  error={!!error?.message}
                  helperText={error?.message && t(error?.message)}
                  multiline
                  inputRef={ref}
                  {...fieldProps}
                />
              )
            }}
          />

          {fields?.length ? (
            <Stack
              spacing={2}
              direction='row'
              display='flex'
              overflow='auto'
              py={2}
            >
              {fields?.map((file, index) => {
                return (
                  <Box key={file?.id}>
                    <Controller
                      name={`files.${index}`}
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error: fieldError }
                      }) => {
                        return (
                          <FileUploadPreview
                            height={150}
                            width={150}
                            smallVariant={true}
                            onChange={async ([file]: File[]) => {
                              try {
                                if (file) {
                                  onChange({
                                    loading: true,
                                    error: undefined,
                                    value: ''
                                  })
                                  const fileRef = firebaseRef(
                                    storage,
                                    `support/${new Date().getTime()}${
                                      file?.name
                                    }`
                                  )

                                  const response = await uploadBytes(
                                    fileRef,
                                    file
                                  )

                                  const fullPath = response?.metadata?.fullPath
                                  onChange({
                                    loading: false,
                                    error: undefined,
                                    value: fullPath
                                  })
                                }
                              } catch (e) {
                                const error = e as Error

                                onChange({
                                  loading: false,
                                  error: error?.message,
                                  value: ''
                                })
                              }
                            }}
                            onDelete={() => {
                              remove(index)
                            }}
                            loading={value?.loading}
                            filePath={value?.value}
                            error={
                              value?.error ||
                              (fieldError ? t('requiredField') : undefined)
                            }
                          />
                        )
                      }}
                    />
                  </Box>
                )
              })}
            </Stack>
          ) : null}

          <Tooltip title={t('upload')}>
            <IconButton onClick={handleAppendFile}>
              <UploadIcon />
            </IconButton>
          </Tooltip>
        </DialogContent>
        <DialogActions>
          <MuiButton
            onClick={handleOnClose}
            startIcon={<CloseIcon />}
            disabled={loading}
          >
            {t('cancel')}
          </MuiButton>
          <MuiButton type='submit' startIcon={<SendIcon />} disabled={loading}>
            {t('submit')}
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CommentFormDialog
