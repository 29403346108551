import React from 'react'

import CommentListForm from '../CommentListForm'
import {
  GetUserApplicationCommentsDocument,
  GetUserApplicationCommentsQuery,
  Order_By,
  useGetUserApplicationCommentsQuery,
  useInsertUserApplicationCommentMutation
} from 'api/generated'
import { useUser } from 'context/auth/user'

export type UserApplicationCommentListFormProps = {
  id?: string
}

const UserApplicationCommentListForm: React.FC<
  UserApplicationCommentListFormProps
> = ({ id }: UserApplicationCommentListFormProps) => {
  const { dbUser } = useUser()
  const queryVariables = {
    where: {
      user_application_id: {
        _eq: id
      }
    },
    order_by: { created_at: Order_By.Desc }
  }
  const { data, loading, error, refetch } = useGetUserApplicationCommentsQuery({
    variables: queryVariables
  })
  const [
    insertUserApplicationComment,
    { loading: mutationLoading, error: mutationError }
  ] = useInsertUserApplicationCommentMutation({
    update: (cache, { data }) => {
      const userApplicationComment = data?.userApplicationComment

      if (userApplicationComment) {
        const existingData = cache.readQuery({
          query: GetUserApplicationCommentsDocument,
          variables: queryVariables
        }) as GetUserApplicationCommentsQuery

        const userApplicationCommentsAggregate =
          existingData?.userApplicationCommentsAggregate
        const userApplicationComments = existingData?.userApplicationComments

        cache.writeQuery({
          query: GetUserApplicationCommentsDocument,
          variables: queryVariables,
          data: {
            userApplicationCommentsAggregate,
            userApplicationComments: [
              userApplicationComment,
              ...userApplicationComments
            ]
          }
        })
      }
    }
  })

  return (
    <CommentListForm
      data={data?.userApplicationComments?.map(comment => {
        return {
          id: comment?.id,
          user: comment?.user,
          comment: comment?.comment,
          createdAt: comment?.created_at as string,
          files: comment?.files
        }
      })}
      loading={loading && !data}
      error={error}
      refetch={refetch}
      onSubmit={(data, onCompletedCallback) => {
        insertUserApplicationComment({
          variables: {
            object: {
              user_application_id: id,
              user_id: dbUser?.id,
              comment: data?.comment,
              files: {
                data: data?.files?.map(file => {
                  return {
                    path: file?.value,
                    folder: 'user-application'
                  }
                })
              }
            }
          },
          onCompleted: data => {
            if (data?.userApplicationComment) {
              onCompletedCallback?.()
            }
          }
        })
      }}
      mutationLoading={mutationLoading}
      mutationError={mutationError}
    />
  )
}

export default UserApplicationCommentListForm
