import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import {
  DataGridPro,
  DataGridProProps,
  GridColTypeDef,
  GridToolbarProps,
  getGridStringOperators
} from '@mui/x-data-grid-pro'
import { styled } from '@mui/material/styles'

import {
  MuiGridToolbar,
  MuiDataGridNoRowsOverlay,
  MuiDataGridNoResultsOverlay
} from 'components'
import { AGGREGATION_ROW_ID } from 'config/constants'
import { useThemeType } from 'context/theme'

type MuiDataGridProps = {
  height?: string
  width?: string
  Toolbar?: React.FC<GridToolbarProps> | null
  NoRowsOverlay?: React.FC | null
  NoResultsOverlay?: React.FC | null
  globalColDefProps?: Record<string, unknown>
} & DataGridProProps

export const customIdColumnType: GridColTypeDef = {
  type: 'string',
  filterOperators: getGridStringOperators().filter(operator =>
    ['equals', 'isAnyOf'].includes(operator.value)
  )
}

export const pageSizeOptions = [10, 50, 100, 200]

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  [`& .MuiDataGrid-columnHeader`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}))

const MuiDataGrid: React.FC<MuiDataGridProps> = ({
  height = '80vh',
  width = '100%',
  Toolbar = MuiGridToolbar,
  NoRowsOverlay = MuiDataGridNoRowsOverlay,
  NoResultsOverlay = MuiDataGridNoResultsOverlay,
  columns,
  globalColDefProps,
  ...props
}: MuiDataGridProps) => {
  const {
    mediaQueries: { mdDown }
  } = useThemeType()

  const columnsData = useMemo(
    () =>
      columns?.map(column => {
        const isIdColumn = column?.field === 'id'

        if (isIdColumn) {
          return {
            minWidth: mdDown ? 150 : 50,
            ...customIdColumnType,
            ...globalColDefProps,
            ...column
          }
        }

        return {
          minWidth: mdDown ? 150 : 50,
          disableExport: column?.type === 'actions',
          ...globalColDefProps,
          ...column
        }
      }),
    [columns, globalColDefProps, mdDown]
  )

  return (
    <Box height={height} width={width}>
      <StyledDataGridPro
        pageSizeOptions={pageSizeOptions}
        showCellVerticalBorder={true}
        showColumnVerticalBorder={true}
        slots={{
          toolbar: Toolbar,
          noRowsOverlay: NoRowsOverlay ?? undefined,
          noResultsOverlay: NoResultsOverlay ?? undefined
        }}
        autosizeOnMount
        autosizeOptions={{
          includeHeaders: true
        }}
        columns={columnsData}
        getRowClassName={params =>
          params?.id === AGGREGATION_ROW_ID ? 'main' : ''
        }
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton'
          }
        }}
        sx={{
          '& .primary': {
            backgroundColor: 'primary.light',
            color: 'text.main'
          },
          '& .secondary': {
            backgroundColor: 'secondary.light',
            color: 'text.main'
          },
          '& .error': {
            backgroundColor: 'error.light',
            color: 'text.main'
          },
          '& .warning': {
            backgroundColor: 'warning.light',
            color: 'text.main'
          },
          '& .grey': {
            backgroundColor: 'grey.300',
            color: 'text.main'
          },
          '& .info': {
            backgroundColor: 'info.light',
            color: 'text.main'
          },
          '& .success': {
            backgroundColor: 'success.light',
            color: 'text.main'
          },
          '& .main': {
            color: 'primary.main'
          }
        }}
        {...props}
      />
    </Box>
  )
}

export default MuiDataGrid
