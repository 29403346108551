import React from 'react'
import { Stack, Box, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { UserNotificationFragmentFragment } from 'api/generated'

type UserNotificationDetailPanelProps = {
  data: UserNotificationFragmentFragment
}

const UserNotificationDetailPanel: React.FC<
  UserNotificationDetailPanelProps
> = ({ data }: UserNotificationDetailPanelProps) => {
  const { t } = useTranslation()

  return (
    <Box p={2} height='100%'>
      <Paper sx={{ height: '100%' }}>
        <Stack p={2}>
          <Typography variant='h6'>{t('senderResponse')}</Typography>
          <pre>{JSON.stringify(data?.sender_response, null, 2)}</pre>
        </Stack>
      </Paper>
    </Box>
  )
}

export default UserNotificationDetailPanel
