import { Palette } from '@mui/material'
import { GridCellParams } from '@mui/x-data-grid-pro'

import {
  UserLivingReviewFragmentFragment,
  User_Living_Review_Status_Enum
} from 'api/generated'

export const getUserLivingReviewStatusColorPalette = (
  status: string,
  palette: Palette
) => {
  switch (status) {
    case User_Living_Review_Status_Enum.Accepted:
      return palette.info.light

    case User_Living_Review_Status_Enum.Denied:
      return palette.error.light

    case User_Living_Review_Status_Enum.Pending:
      return palette.warning.light

    default:
      return ''
  }
}

export const getUserLivingReviewCellClassName = (
  params: GridCellParams
): string => {
  const row = params?.row as UserLivingReviewFragmentFragment

  switch (params.field) {
    case 'status': {
      const status = row?.status
      switch (status) {
        case User_Living_Review_Status_Enum.Pending:
          return 'warning'

        case User_Living_Review_Status_Enum.Denied:
          return 'error'

        case User_Living_Review_Status_Enum.Accepted:
          return 'info'

        default:
          return ''
      }
    }

    default:
      return ''
  }
}

export const getDisableAcceptUserLivingReview = (status?: string) =>
  [User_Living_Review_Status_Enum.Accepted].includes(
    status as User_Living_Review_Status_Enum
  )

export const getDisableDenyUserLivingReview = (status?: string) =>
  [User_Living_Review_Status_Enum.Denied].includes(
    status as User_Living_Review_Status_Enum
  )

export default {
  getUserLivingReviewStatusColorPalette,
  getUserLivingReviewCellClassName
}
