import React from 'react'
import { AppBar, Toolbar, Box } from '@mui/material'

import MuiLink from 'components/MuiLink'
import Logo from 'components/Logo'

const Header: React.FC = () => {
  return (
    <>
      <AppBar position='fixed' color='default'>
        <Toolbar>
          <Box
            display='flex'
            flex={1}
            justifyContent='space-between'
            alignItems='center'
          >
            <MuiLink to={`/`}>
              <Logo height={50} />
            </MuiLink>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
}

export default Header
