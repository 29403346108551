import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CardHeader,
  CardContent,
  Stack,
  Paper,
  List,
  Box,
  Skeleton,
  Tooltip,
  IconButton
} from '@mui/material'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ApolloError } from '@apollo/client'
import { Send as SendIcon, Upload as UploadIcon } from '@mui/icons-material'
import { uploadBytes, ref as firebaseRef } from 'firebase/storage'

import MuiCard from '../MuiCard'
import CommentItem from '../CommentItem'
import MuiTextField from '../MuiTextField'
import MuiButton from '../MuiButton'
import MuiSnackbar from '../MuiSnackbar'
import QueryError from '../QueryError'
import {
  CommentSchemaType,
  commentSchema,
  defaultCommentValues
} from 'schemas/comment'
import { BaseUserFragmentFragment } from 'api/generated'
import FileUploadPreview from '../FileUploadPreview'
import firebase from 'lib/firebase'
import { defaultFileValues } from 'schemas/common'

const { storage } = firebase

export type FileType = {
  id: string
  path: string
}

export type CommentType = {
  id: string
  user: BaseUserFragmentFragment
  comment: string
  createdAt: string
  files: FileType[]
}

export type CommentListFormType = {
  data?: CommentType[]
  loading?: boolean
  error?: ApolloError
  refetch?: () => void
  onSubmit: (data: CommentSchemaType, onCompletedCallback?: () => void) => void
  mutationLoading?: boolean
  mutationError?: ApolloError
}

const CommentListForm: React.FC<CommentListFormType> = ({
  data,
  loading,
  error,
  refetch,
  onSubmit,
  mutationLoading,
  mutationError
}: CommentListFormType) => {
  const { t } = useTranslation()
  const { control, handleSubmit, reset } = useForm<CommentSchemaType>({
    resolver: yupResolver(commentSchema),
    defaultValues: defaultCommentValues
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'files'
  })

  const handleOnSubmit = handleSubmit(data => {
    onSubmit(data, () => {
      reset()
    })
  })

  const handleAppendFile = useCallback(() => {
    append(defaultFileValues)
  }, [append])

  return (
    <>
      <MuiSnackbar
        snackbarProps={{
          open: !!mutationError
        }}
        alertProps={{
          severity: 'error'
        }}
        message={mutationError?.message}
      />

      <MuiCard>
        <CardHeader title={t('comments')} />
        <CardContent>
          {error ? (
            <QueryError error={error} loading={loading} refetch={refetch} />
          ) : loading ? (
            <Stack spacing={1} width='100%'>
              {[...Array(4)].map((v, i) => {
                return (
                  <Skeleton
                    key={i}
                    variant='rectangular'
                    width='100%'
                    height={150}
                  />
                )
              })}
            </Stack>
          ) : (
            <>
              <Stack spacing={1}>
                <Paper
                  elevation={1}
                  sx={{ backgroundColor: 'background.default' }}
                >
                  <form onSubmit={handleOnSubmit}>
                    <Box p={2}>
                      <Controller
                        name='comment'
                        control={control}
                        defaultValue=''
                        render={({
                          field: { ref, ...fieldProps },
                          fieldState: { error }
                        }) => {
                          return (
                            <MuiTextField
                              label={t('comment')}
                              error={!!error?.message}
                              helperText={error?.message && t(error?.message)}
                              multiline
                              inputRef={ref}
                              {...fieldProps}
                            />
                          )
                        }}
                      />

                      {fields?.length ? (
                        <Stack
                          spacing={2}
                          direction='row'
                          display='flex'
                          overflow='auto'
                          py={2}
                        >
                          {fields?.map((file, index) => {
                            return (
                              <Box key={file?.id}>
                                <Controller
                                  name={`files.${index}`}
                                  control={control}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error: fieldError }
                                  }) => {
                                    return (
                                      <FileUploadPreview
                                        height={150}
                                        width={150}
                                        smallVariant={true}
                                        onChange={async ([file]: File[]) => {
                                          try {
                                            if (file) {
                                              onChange({
                                                loading: true,
                                                error: undefined,
                                                value: ''
                                              })
                                              const fileRef = firebaseRef(
                                                storage,
                                                `support/${new Date().getTime()}${
                                                  file?.name
                                                }`
                                              )

                                              const response =
                                                await uploadBytes(fileRef, file)

                                              const fullPath =
                                                response?.metadata?.fullPath
                                              onChange({
                                                loading: false,
                                                error: undefined,
                                                value: fullPath
                                              })
                                            }
                                          } catch (e) {
                                            const error = e as Error

                                            onChange({
                                              loading: false,
                                              error: error?.message,
                                              value: ''
                                            })
                                          }
                                        }}
                                        onDelete={() => {
                                          remove(index)
                                        }}
                                        loading={value?.loading}
                                        filePath={value?.value}
                                        error={
                                          value?.error ||
                                          (fieldError
                                            ? t('requiredField')
                                            : undefined)
                                        }
                                      />
                                    )
                                  }}
                                />
                              </Box>
                            )
                          })}
                        </Stack>
                      ) : null}

                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                      >
                        <Tooltip title={t('upload')}>
                          <IconButton onClick={handleAppendFile}>
                            <UploadIcon />
                          </IconButton>
                        </Tooltip>

                        <MuiButton
                          type='submit'
                          disabled={mutationLoading}
                          startIcon={<SendIcon />}
                        >
                          {t('submit')}
                        </MuiButton>
                      </Box>
                    </Box>
                  </form>
                </Paper>

                <List sx={{ height: '50vh', overflow: 'auto', width: '100%' }}>
                  <Stack spacing={1}>
                    {data?.map(comment => {
                      return (
                        <CommentItem
                          key={comment?.id}
                          id={comment?.id}
                          user={comment?.user}
                          comment={comment?.comment}
                          createdAt={comment?.createdAt as string}
                          files={comment?.files}
                        />
                      )
                    })}
                  </Stack>
                </List>
              </Stack>
            </>
          )}
        </CardContent>
      </MuiCard>
    </>
  )
}

export default CommentListForm
