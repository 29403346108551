import React from 'react'
import { Stack, Box, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ApartmentFragmentFragment } from 'api/generated'
import RemoteImage from '../RemoteImage'

type ApartmentDetailPanelProps = {
  data: ApartmentFragmentFragment
}

const ApartmentDetailPanel: React.FC<ApartmentDetailPanelProps> = ({
  data
}: ApartmentDetailPanelProps) => {
  const { t } = useTranslation()
  const imagePath = data?.profile_photo

  return (
    <Box p={2} height='100%'>
      <Paper sx={{ height: '100%' }}>
        <Stack direction='row' spacing={2} p={2} height='100%'>
          <Stack spacing={2}>
            <Typography variant='h6'>{t('image')}</Typography>
            <RemoteImage filePath={imagePath} />
          </Stack>
        </Stack>
      </Paper>
    </Box>
  )
}

export default ApartmentDetailPanel
