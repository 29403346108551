import { Palette } from '@mui/material'
import { GridCellParams } from '@mui/x-data-grid-pro'

import {
  BuildingTourFragmentFragment,
  Building_Tour_Status_Enum
} from 'api/generated'

export const getBuildingTourStatusColorPalette = (
  status: string,
  palette: Palette
) => {
  switch (status) {
    case Building_Tour_Status_Enum.Accepted:
      return palette.info.light

    case Building_Tour_Status_Enum.Denied:
      return palette.error.light

    case Building_Tour_Status_Enum.Pending:
      return palette.warning.light

    default:
      return ''
  }
}

export const getBuildingTourCellClassName = (
  params: GridCellParams
): string => {
  const row = params?.row as BuildingTourFragmentFragment

  switch (params.field) {
    case 'status': {
      const status = row?.status
      switch (status) {
        case Building_Tour_Status_Enum.Pending:
          return 'warning'

        case Building_Tour_Status_Enum.Denied:
          return 'error'

        case Building_Tour_Status_Enum.Accepted:
          return 'info'

        default:
          return ''
      }
    }

    default:
      return ''
  }
}

export const getDisableAcceptBuildingTour = (status?: string) =>
  [Building_Tour_Status_Enum.Accepted].includes(
    status as Building_Tour_Status_Enum
  )

export const getDisableDenyBuildingTour = (status?: string) =>
  [Building_Tour_Status_Enum.Denied].includes(
    status as Building_Tour_Status_Enum
  )

export default {
  getBuildingTourStatusColorPalette,
  getBuildingTourCellClassName
}
