export enum TABLE_ENUM {
  USER = 'user',
  BUILDING = 'building',
  APARTMENT = 'apartment',
  APARTMENT_TYPE = 'apartment_type',
  DEVICE = 'device',
  USER_APPLICATION = 'user_application',
  SUPPORT_TICKET = 'support_ticket',
  BUILDING_TOUR = 'building_tour',
  APARTMENT_CHECKLIST = 'apartment_checklist',
  USER_LIVING_REVIEW = 'user_living_review'
}

export default {
  TABLE_ENUM
}
