import React from 'react'
import { Box, BoxProps } from '@mui/material'

import LanguageSelect from '../../LanguageSelect'
import ThemeSelect from '../../ThemeSelect'

type FooterProps = BoxProps

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      component='footer'
      {...props}
    >
      <LanguageSelect />

      <ThemeSelect />
    </Box>
  )
}

export default Footer
