import React from 'react'
import { Box, Skeleton, SkeletonProps } from '@mui/material'

const SkeletonTextField: React.FC<SkeletonProps> = (props: SkeletonProps) => {
  return (
    <Box mt={2}>
      <Skeleton variant='rectangular' width='100%' height={55} {...props} />
    </Box>
  )
}

export default SkeletonTextField
