import React from 'react'
import { Stack, Box, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ApartmentTypeFragmentFragment } from 'api/generated'
import RemoteImage from '../RemoteImage'

type ApartmentTypeDetailPanelProps = {
  data: ApartmentTypeFragmentFragment
}

const ApartmentTypeDetailPanel: React.FC<ApartmentTypeDetailPanelProps> = ({
  data
}: ApartmentTypeDetailPanelProps) => {
  const { t } = useTranslation()
  const photos = data?.photos

  return (
    <Box p={2} height='100%'>
      <Paper sx={{ height: '100%' }}>
        <Stack direction='row' spacing={2} p={2} height='100%'>
          {photos?.map(photo => {
            const path = photo?.path

            return (
              <Stack key={photo?.id} spacing={2}>
                <Typography variant='h6'>{t('image')}</Typography>
                <RemoteImage filePath={path} />
              </Stack>
            )
          })}
        </Stack>
      </Paper>
    </Box>
  )
}

export default ApartmentTypeDetailPanel
