import React from 'react'
import { Paper, Stack, Typography } from '@mui/material'
import { formatDistanceToNow } from 'date-fns'

import { useThemeType } from 'context/theme'
import { CommentType } from '../CommentListForm'
import RemoteImage from '../RemoteImage'

const CommentItem: React.FC<CommentType> = ({
  user,
  comment,
  createdAt,
  files
}) => {
  const { dateFnsLocale } = useThemeType()

  return (
    <Paper elevation={1} sx={{ backgroundColor: 'background.default' }}>
      <Stack spacing={1} p={2}>
        <Typography variant='subtitle2'>{`${user?.first_name} ${user?.last_name}`}</Typography>
        <Typography variant='body2' whiteSpace='pre-line'>
          {comment}
        </Typography>

        {files?.length ? (
          <Stack
            spacing={2}
            direction='row'
            display='flex'
            overflow='auto'
            py={2}
          >
            {files?.map(file => {
              return (
                <RemoteImage
                  key={file?.id}
                  filePath={file?.path}
                  width={150}
                  height={150}
                />
              )
            })}
          </Stack>
        ) : null}

        <Typography variant='caption'>
          {formatDistanceToNow(new Date(createdAt), {
            addSuffix: true,
            locale: dateFnsLocale
          })}
        </Typography>
      </Stack>
    </Paper>
  )
}

export default CommentItem
