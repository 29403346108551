import {
  GridFilterModel,
  GridLogicOperator,
  GridSortModel
} from '@mui/x-data-grid-pro'
import set from 'lodash.set'
import get from 'lodash.get'
import { parseISO } from 'date-fns'
import { TFunction } from 'i18next'

import { TABLE_ENUM } from 'constants/tables'

const initialWidth = 180
const initialPage = 0
const initialPageSize = 10
export const initialSortModel: GridSortModel = [{ field: 'id', sort: 'asc' }]
const operatorsWithoutValue = ['isNotEmpty', 'isEmpty']
const getIsOperatorWithoutValue = (operator: string) =>
  operatorsWithoutValue.includes(operator)

export const getOrderBy = (
  sortModel?: GridSortModel,
  defaultSortModel: GridSortModel = initialSortModel
): Record<string, unknown> => {
  const field = sortModel?.[0]?.field
  const sort = sortModel?.[0]?.sort

  if (field && sort) {
    const orderBy = {}
    set(orderBy, field, sort)
    return orderBy
  } else {
    const orderBy = {}
    const initialField = defaultSortModel?.[0].field
    const initialSort = defaultSortModel?.[0].sort
    set(orderBy, initialField, initialSort)
    return orderBy
  }
}

export const getHasuraValue = (
  value: string,
  muiOperator?: string
): string | number | Date | boolean => {
  switch (muiOperator) {
    case 'contains':
      return `%${value}%`

    case 'startsWith':
      return `${value}%`

    case 'endsWith':
      return `%${value}`

    case 'before':
    case 'onOrBefore':
    case 'after':
    case 'onOrAfter':
      return parseISO(value)

    case '=':
    case '!=':
    case '>':
    case '>=':
    case '<':
    case '<=':
      return Number(value)

    case 'isEmpty':
      return true

    case 'isNotEmpty':
      return false

    default:
      return value || ''
  }
}

export const getHasuraOperator = (muiOperator?: string): string => {
  switch (muiOperator) {
    case 'and':
      return '_and'

    case 'or':
      return '_or'

    case 'equals':
    case 'is':
    case '=':
      return '_eq'

    case '!=':
    case 'not':
      return '_neq'

    case '>':
    case 'after':
      return '_gt'

    case '<':
    case 'before':
      return '_lt'

    case '>=':
    case 'onOrAfter':
      return '_gte'

    case '<=':
    case 'onOrBefore':
      return '_lte'

    case 'contains':
    case 'startsWith':
    case 'endsWith':
      return '_ilike'

    case 'isEmpty':
    case 'isNotEmpty':
      return '_is_null'

    case 'isAnyOf':
      return '_in'

    default:
      throw new Error(`operator ${muiOperator} not available`)
  }
}

const getWhere = (
  filterModel?: GridFilterModel,
  withDeleted = false
): Record<string, unknown> | undefined => {
  if (!filterModel || !filterModel?.items?.length) {
    return withDeleted ? {} : { _and: [{ deleted_at: { _is_null: true } }] }
  }

  const linkOperator = getHasuraOperator(filterModel.logicOperator)
  const where = { [linkOperator]: [] }
  for (const item of filterModel.items) {
    const { field: columnField, operator: operatorValue, value } = item
    const isOperatorWithoutValue = getIsOperatorWithoutValue(operatorValue)

    if (value || isOperatorWithoutValue) {
      const hasuraOperator = getHasuraOperator(operatorValue)
      const hasuraValue = getHasuraValue(value, operatorValue)
      const whereItem = withDeleted ? {} : { deleted_at: { _is_null: true } }
      set(whereItem, `${columnField}.${hasuraOperator}`, hasuraValue)
      where?.[linkOperator]?.push(whereItem as never)
    }
  }

  return where
}

export type GeneratePathProps = {
  pageSize?: number
  page?: number
  sortModel?: GridSortModel
  filterModel?: GridFilterModel
}

export const generatePath = ({
  pageSize = 10,
  page = 0,
  sortModel = initialSortModel,
  filterModel
}: GeneratePathProps): string =>
  `?pageSize=${pageSize}&page=${page}&sortModel=${JSON.stringify(
    sortModel
  )}&filterModel=${JSON.stringify(filterModel)}`

export const EMPTY_FILTER_MODEL: GridFilterModel = {
  items: [],
  logicOperator: GridLogicOperator.And,
  quickFilterValues: [],
  quickFilterLogicOperator: GridLogicOperator.And
}

export const DELETED_FILTER_MODEL: GridFilterModel = {
  items: [
    {
      field: 'deleted_at',
      operator: 'isNotEmpty'
    }
  ],
  logicOperator: GridLogicOperator.And,
  quickFilterValues: [],
  quickFilterLogicOperator: GridLogicOperator.And
}

export const getFilterPath = (
  route: string,
  value: string,
  field = 'id',
  operator = 'equals'
): string => {
  const filterModel: GridFilterModel = {
    items: [
      {
        id: 1,
        field: field,
        operator,
        value
      }
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: []
  }

  const path = generatePath({
    filterModel
  })

  return `/${route}${path}`
}

export const getRowCount = (totalCount?: number): number =>
  totalCount !== undefined ? totalCount : -1

export type ValueFormatterArgsType<T> = {
  value?: string
  row?: T
  key?: string
  t?: TFunction
}

export const valueFormatter = <T>({
  value,
  row,
  key,
  t
}: ValueFormatterArgsType<T>): string => {
  if (value && t) {
    return t(value)
  }

  if (row && key) {
    const value = get(row, key) || ''
    return t ? t(value) : value
  }

  return value ? value : ''
}

export const getTablePathName = (
  tableName: string | null | undefined
): string | null => {
  if (!tableName) {
    return null
  }

  switch (tableName) {
    case TABLE_ENUM.USER:
    case TABLE_ENUM.BUILDING:
    case TABLE_ENUM.APARTMENT:
    case TABLE_ENUM.DEVICE:
      return `/${tableName}s`

    case TABLE_ENUM.APARTMENT_TYPE:
      return '/apartment-types'

    case TABLE_ENUM.USER_APPLICATION:
      return '/user-applications'

    case TABLE_ENUM.SUPPORT_TICKET:
      return '/support-tickets'

    case TABLE_ENUM.BUILDING_TOUR:
      return '/building-tours'

    case TABLE_ENUM.APARTMENT_CHECKLIST:
      return '/apartment-checklists'

    case TABLE_ENUM.USER_LIVING_REVIEW:
      return '/user-living-reviews'

    default:
      return null
  }
}

export default {
  initialWidth,
  initialPage,
  initialPageSize,
  initialSortModel,
  getOrderBy,
  getHasuraValue,
  getHasuraOperator,
  getWhere,
  generatePath,
  EMPTY_FILTER_MODEL,
  getFilterPath,
  getRowCount,
  valueFormatter,
  getTablePathName
}
