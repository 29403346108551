import { Palette } from '@mui/material'
import { GridCellParams } from '@mui/x-data-grid-pro'

import {
  UserApplicationFragmentFragment,
  User_Application_Status_Enum,
  Apartment_Rental_Type_Enum
} from 'api/generated'

export const getUserApplicationStatusColorPalette = (
  status: string,
  palette: Palette
) => {
  switch (status) {
    case User_Application_Status_Enum.Accepted:
      return palette.info.light

    case User_Application_Status_Enum.Denied:
      return palette.error.light

    case User_Application_Status_Enum.Completed:
      return palette.success.light

    case User_Application_Status_Enum.Incompleted:
      return palette?.grey?.[300]

    case User_Application_Status_Enum.Pending:
      return palette.warning.light

    default:
      return ''
  }
}

export const getUserApplicationCellClassName = (
  params: GridCellParams
): string => {
  const row = params?.row as UserApplicationFragmentFragment

  switch (params.field) {
    case 'status': {
      const status = row?.status
      switch (status) {
        case User_Application_Status_Enum.Accepted:
          return 'info'

        case User_Application_Status_Enum.Denied:
          return 'error'

        case User_Application_Status_Enum.Completed:
          return 'success'

        case User_Application_Status_Enum.Incompleted:
          return 'grey'

        case User_Application_Status_Enum.Pending:
          return 'warning'

        default:
          return ''
      }
    }

    case 'rental_type': {
      const rentalType = row?.rental_type
      switch (rentalType) {
        case Apartment_Rental_Type_Enum.LongTherm:
          return 'success'

        case Apartment_Rental_Type_Enum.ShortTherm:
          return 'info'

        default:
          return ''
      }
    }

    default:
      return ''
  }
}

export const getDisableAcceptUserApplication = (status?: string) =>
  [
    User_Application_Status_Enum.Completed,
    User_Application_Status_Enum.Incompleted,
    User_Application_Status_Enum.Accepted
  ].includes(status as User_Application_Status_Enum)

export const getDisableDenyUserApplication = (status?: string) =>
  [
    User_Application_Status_Enum.Incompleted,
    User_Application_Status_Enum.Denied
  ].includes(status as User_Application_Status_Enum)

export default {
  getUserApplicationStatusColorPalette,
  getUserApplicationCellClassName,
  getDisableAcceptUserApplication,
  getDisableDenyUserApplication
}
