import React, { useCallback } from 'react'
import {
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridExportMenuItemProps,
  useGridApiContext
} from '@mui/x-data-grid'
import MenuItem from '@mui/material/MenuItem'
import { ButtonProps } from '@mui/material/Button'
import { utils, writeFileXLSX } from 'xlsx'
import { useTranslation } from 'react-i18next'

export const GridExcelExportMenuItem: React.FC<
  GridExportMenuItemProps<object>
> = (props: GridExportMenuItemProps<object>) => {
  const { t } = useTranslation()
  const apiRef = useGridApiContext()

  const handleExportExcel = useCallback(() => {
    const rows = apiRef.current.getRowModels()
    const columns = apiRef.current.getVisibleColumns()
    const formattedRows: Record<string, unknown>[] = []

    Array.from(rows.keys()).forEach(key => {
      const formattedRow: Record<string, unknown> = {}

      columns?.forEach(column => {
        const field = column?.field
        const headerName = column?.headerName || field
        if (!column?.disableExport) {
          const cellValue = apiRef.current.getCellParams(key, field)
          formattedRow[headerName] = cellValue?.formattedValue
        }
      })

      formattedRows.push(formattedRow)
    })

    const worksheet = utils.json_to_sheet(formattedRows)
    const wb = utils.book_new()
    utils.book_append_sheet(wb, worksheet, 'Sheet1')
    writeFileXLSX(wb, 'domo.xlsx')

    props?.hideMenu?.()
  }, [apiRef, props])

  return <MenuItem onClick={handleExportExcel}>{t('downloadAsExcel')}</MenuItem>
}

const MuiGridToolbarExport: React.FC<ButtonProps> = (props: ButtonProps) => {
  return (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem />
      <GridExcelExportMenuItem />
    </GridToolbarExportContainer>
  )
}

export default MuiGridToolbarExport
