import React from 'react'
import { Box, BoxProps } from '@mui/material'

import Logo from 'components/Logo'

type MainProps = BoxProps

const Main: React.FC<MainProps> = ({ children, ...props }: MainProps) => {
  return (
    <Box component='main' p={2} {...props}>
      <Logo
        height={100}
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      />
      {children}
    </Box>
  )
}

export default Main
