import { Palette } from '@mui/material'
import { GridCellParams } from '@mui/x-data-grid-pro'

import {
  SupportTicketFragmentFragment,
  Support_Ticket_Status_Enum,
  Support_Ticket_Type_Enum
} from 'api/generated'

export const getSupportTicketTypeColorPalette = (
  type: string,
  palette: Palette
) => {
  switch (type) {
    case Support_Ticket_Type_Enum.Other:
      return palette.info.light

    case Support_Ticket_Type_Enum.Water:
      return palette.error.light

    case Support_Ticket_Type_Enum.Appliances:
      return palette?.grey?.[300]

    case Support_Ticket_Type_Enum.Hvac:
      return palette.primary.light

    case Support_Ticket_Type_Enum.Internet:
      return palette.success.light

    case Support_Ticket_Type_Enum.AccessControl:
      return palette.warning.light

    default:
      return ''
  }
}

export const getSupportTicketCellClassName = (
  params: GridCellParams
): string => {
  const row = params?.row as SupportTicketFragmentFragment

  switch (params.field) {
    case 'status': {
      const status = row?.status
      switch (status) {
        case Support_Ticket_Status_Enum.New:
          return 'info'

        case Support_Ticket_Status_Enum.Resolved:
          return 'success'

        case Support_Ticket_Status_Enum.Answered:
          return 'grey'

        default:
          return ''
      }
    }

    case 'type': {
      const type = row?.type
      switch (type) {
        case Support_Ticket_Type_Enum.Water:
          return 'error'

        case Support_Ticket_Type_Enum.Internet:
          return 'success'

        case Support_Ticket_Type_Enum.Hvac:
          return 'primary'

        case Support_Ticket_Type_Enum.AccessControl:
          return 'warning'

        case Support_Ticket_Type_Enum.Appliances:
          return 'grey'

        case Support_Ticket_Type_Enum.Other:
          return 'info'

        default:
          return ''
      }
    }

    default:
      return ''
  }
}

export default {
  getSupportTicketCellClassName
}
