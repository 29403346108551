import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split,
  from,
  ApolloLink
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'

import { hasuraConfig } from 'config'
import firebaseLib from 'lib/firebase'

const { auth } = firebaseLib

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        user_by_pk(existing, { canRead, args, toReference }) {
          return canRead(existing)
            ? existing
            : toReference({ __typename: 'user', id: args?.id })
        },
        building_by_pk(existing, { canRead, args, toReference }) {
          return canRead(existing)
            ? existing
            : toReference({ __typename: 'building', id: args?.id })
        },
        apartment_by_pk(existing, { canRead, args, toReference }) {
          return canRead(existing)
            ? existing
            : toReference({ __typename: 'apartment', id: args?.id })
        },
        apartment_type_by_pk(existing, { canRead, args, toReference }) {
          return canRead(existing)
            ? existing
            : toReference({ __typename: 'apartment_type', id: args?.id })
        },
        device_by_pk(existing, { canRead, args, toReference }) {
          return canRead(existing)
            ? existing
            : toReference({ __typename: 'device', id: args?.id })
        },
        user_application_by_pk(existing, { canRead, args, toReference }) {
          return canRead(existing)
            ? existing
            : toReference({ __typename: 'user_application', id: args?.id })
        },
        support_ticket_by_pk(existing, { canRead, args, toReference }) {
          return canRead(existing)
            ? existing
            : toReference({ __typename: 'support_ticket', id: args?.id })
        },
        building_tour_by_pk(existing, { canRead, args, toReference }) {
          return canRead(existing)
            ? existing
            : toReference({ __typename: 'building_tour', id: args?.id })
        },
        apartment_checklist_by_pk(existing, { canRead, args, toReference }) {
          return canRead(existing)
            ? existing
            : toReference({ __typename: 'apartment_checklist', id: args?.id })
        }
      }
    }
  }
})

const getLink = (): ApolloLink => {
  const httpLink = new HttpLink({
    uri: hasuraConfig.httpLinkUrl,
    credentials: 'same-origin'
  })

  const wsLink = new GraphQLWsLink(
    createClient({
      url: hasuraConfig.wsLinkUrl,
      connectionParams: async () => {
        const token = await auth.currentUser?.getIdToken()

        return {
          headers: {
            Authorization: token ? `Bearer ${token}` : undefined
          }
        }
      }
    })
  )

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink
  )

  const authLink = setContext(async (_, { headers }) => {
    const token = await auth.currentUser?.getIdToken()

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : undefined
      }
    }
  })

  return from([authLink, link])
}

const client = new ApolloClient({
  link: getLink(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  },
  cache
})

client.onClearStore(async () => {
  client.setLink(getLink())
})

export default client
