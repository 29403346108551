import React from 'react'
import { Stack, Box, Paper, Typography, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FileOpen as FileOpenIcon } from '@mui/icons-material'

import MuiDataGrid from '../MuiDataGrid'
import Image from '../Image'
import {
  UserApplicationFragmentFragment,
  UserApplicationIncomeDocumentFragmentFragment
} from 'api/generated'
import { dataGridCurrencyFormatter } from 'utils/currency'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import { getUrlFile } from 'utils/firebase'
import { useThemeType } from 'context/theme'

type UserApplicationsDetailPanelProps = {
  data: UserApplicationFragmentFragment
}

const UserApplicationsDetailPanel: React.FC<
  UserApplicationsDetailPanelProps
> = ({ data }: UserApplicationsDetailPanelProps) => {
  const { t } = useTranslation()
  const {
    mediaQueries: { mdDown }
  } = useThemeType()
  const signature = data?.signature
  const termsSignature = data?.terms_signature
  const agreementSignature = data?.agreement_signature

  return (
    <Box p={2} height='100%'>
      <Paper sx={{ height: '100%' }}>
        <Stack direction='row' spacing={2} p={2} height='100%'>
          <Stack spacing={2}>
            <Typography variant='h6'>{t('signature')}</Typography>
            {signature ? (
              <Image
                src={signature}
                width={300}
                height='100%'
                alt='signature'
              />
            ) : (
              <Typography variant='body2'>No signature</Typography>
            )}
          </Stack>

          <Stack spacing={2}>
            <Typography variant='h6'>{t('Lease terms signature')}</Typography>
            {termsSignature ? (
              <Image
                src={termsSignature}
                width={300}
                height='100%'
                alt='Lease terms signature'
              />
            ) : (
              <Typography variant='body2'>No signature</Typography>
            )}
          </Stack>

          <Stack spacing={2}>
            <Typography variant='h6'>
              {t('Lease agreement signature')}
            </Typography>
            {agreementSignature ? (
              <Image
                src={agreementSignature}
                width={300}
                height='100%'
                alt='Lease agreement signature'
              />
            ) : (
              <Typography variant='body2'>No signature</Typography>
            )}
          </Stack>

          <Stack spacing={2}>
            <Typography variant='h6'>{t('Addresses')}</Typography>
            <MuiDataGrid
              rows={data?.addresses || []}
              Toolbar={null}
              NoRowsOverlay={null}
              height='100%'
              disableColumnMenu={true}
              hideFooter={true}
              density='compact'
              initialState={{
                pinnedColumns: mdDown ? undefined : { right: ['actions'] }
              }}
              columns={[
                {
                  field: 'street_address',
                  headerName: `${t('Street address')}`
                },
                {
                  field: 'apt',
                  headerName: `${t('Apt')}`
                },
                {
                  field: 'state',
                  headerName: `${t('State')}`
                },
                {
                  field: 'city',
                  headerName: `${t('City')}`
                },
                {
                  field: 'zip',
                  headerName: `${t('Zip')}`
                },
                {
                  field: 'reference_contact_number',
                  headerName: `${t('Reference contact number')}`
                },
                {
                  field: 'time_of_living',
                  headerName: `${t('Time of living')}`
                },
                {
                  field: 'monthly_rent',
                  headerName: `${t('Monthly rent')}`,
                  type: 'number',
                  valueFormatter: dataGridCurrencyFormatter
                },
                {
                  field: 'reason_of_moving',
                  headerName: `${t('Reason of moving')}`
                }
              ]}
            />
          </Stack>

          <Stack spacing={2}>
            <Typography variant='h6'>{t('Incomes')}</Typography>
            <MuiDataGrid
              rows={data?.incomes || []}
              Toolbar={null}
              NoRowsOverlay={null}
              height='100%'
              disableColumnMenu={true}
              hideFooter={true}
              density='compact'
              columns={[
                {
                  field: 'status',
                  headerName: `${t('Status')}`,
                  valueGetter: value => t(value)
                },
                {
                  field: 'job_title',
                  headerName: `${t('Job title')}`
                },
                {
                  field: 'employer',
                  headerName: `${t('Employer')}`
                },
                {
                  field: 'employer_since',
                  headerName: `${t('Employer since')}`
                },
                {
                  field: 'monthly_income',
                  headerName: `${t('Monthly income')}`,
                  type: 'number',
                  valueFormatter: dataGridCurrencyFormatter
                },
                {
                  field: 'employer_contact_name',
                  headerName: `${t('Employer contact name')}`
                },
                {
                  field: 'employer_contact_email',
                  headerName: `${t('Employer contact email')}`
                },
                {
                  field: 'employer_contact_phone_number',
                  headerName: `${t('Employer contact phone number')}`
                },
                {
                  field: 'additional_information',
                  headerName: `${t('Additional information')}`
                }
              ]}
            />
          </Stack>

          <Stack spacing={2}>
            <Typography variant='h6'>{t('Income documents')}</Typography>
            <MuiDataGrid
              rows={data?.income_documents || []}
              Toolbar={null}
              NoRowsOverlay={null}
              height='100%'
              disableColumnMenu={true}
              hideFooter={true}
              density='compact'
              columns={[
                {
                  field: 'name',
                  headerName: `${t('Name')}`
                },
                {
                  field: 'size',
                  headerName: `${t('Size')}`
                },
                {
                  field: 'type',
                  headerName: `${t('Type')}`
                },
                {
                  field: 'actions',
                  type: 'actions',
                  headerName: `${t('actions')}`,
                  cellClassName: 'actions',
                  flex: 1,
                  getActions: ({ id, row }) => {
                    const incomeDocument =
                      row as UserApplicationIncomeDocumentFragmentFragment

                    return [
                      <GridActionsCellItem
                        key={id}
                        icon={
                          <Tooltip title={t('Show document')}>
                            <FileOpenIcon />
                          </Tooltip>
                        }
                        label={t('Show document')}
                        className='textPrimary'
                        color='inherit'
                        onClick={async () => {
                          const urlFile = await getUrlFile(
                            `document/${incomeDocument?.name}`
                          )
                          window?.open(urlFile, '_black')
                        }}
                      />
                    ]
                  }
                }
              ]}
            />
          </Stack>
        </Stack>
      </Paper>
    </Box>
  )
}

export default UserApplicationsDetailPanel
