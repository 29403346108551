export default {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
  appName: process.env.REACT_APP_FIREBASE_APP_NAME || '',
  useEmulator: process.env.REACT_APP_USE_FIREBASE_EMULATOR || false,
  authEmulatorPort: process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_PORT || '',
  functionsEmulatorPort:
    process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_PORT || '',
  storageEmulatorPort:
    process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_PORT || '',
  functionsBaseUrl: process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL || '',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || ''
}
