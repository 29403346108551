import * as yup from 'yup'

export type IdSchemaType = {
  id: string
}

export type EmailSchemaType = {
  email: string
}

export type AutoCompleteSchemaType = {
  value: string
  autoCompleteValue?: string
}

export type FileType = {
  id?: string | undefined | null
  value: string
  loading?: boolean
  error?: string | undefined | null
}

export const string = yup.string()
export const object = yup.object()
export const number = yup.number()
export const array = yup.array()
export const arrayRequired = yup.array().required('requiredField')
export const date = yup.date()
export const bool = yup.bool()
export const email = string.email('invalidEmail')
export const uuid = string.uuid('requiredField')
export const objectRequired = yup.object().required('requiredField')
export const json = yup.object().json()
export const jsonRequired = yup.object().json().required('requiredField')
export const jsonNullable = yup.object().json().nullable()
export const stringRequired = string.required('requiredField')
export const stringNullable = string.nullable()
export const boolRequired = bool.required('requiredField')
export const boolNullable = bool.nullable()
export const numberRequired = number.required('requiredField')
export const numberNullable = number.nullable()
export const emailRequired = email.required('requiredField')
export const emailNullable = email.nullable()
export const uuidRequired = uuid.required('requiredField')
export const uuidNullable = uuid.nullable()
export const dateRequired = date.required('requiredField')
export const dateNullable = date.nullable()

export const phoneNumberSchema = object.shape({
  id: stringNullable,
  value: stringRequired,
  type: stringRequired,
  description: stringNullable
})

export const addressSchema = object.shape({
  id: stringNullable,
  value: stringRequired,
  type: stringRequired,
  description: stringNullable
})

export const fileSchema = object.shape({
  id: stringNullable,
  value: stringRequired,
  loading: bool,
  error: stringNullable
})

export const fileSchemaRequired = fileSchema.required('requiredField')
export const fileArraySchema = array.of(fileSchema)
export const fileArraySchemaRequired = fileArraySchema.required

export const idAutoCompleteSchema = object.shape({
  value: uuidRequired,
  autoCompleteValue: stringNullable
})

export const idSchema = object.shape({
  id: uuidRequired
})

export const emailSchema = object.shape({
  email: emailRequired
})

export const defaultAutocompleteValues: AutoCompleteSchemaType = {
  value: '',
  autoCompleteValue: ''
}

export const defaultFileValues: FileType = {
  id: '',
  loading: false,
  error: '',
  value: ''
}
